import React, { useState } from 'react';
import { Button, Select, MenuItem, Modal, Box, TextField, Checkbox, TableRow, TableCell } from '@mui/material';
import DurationPicker from 'react-duration-picker';
import { toast } from 'react-toastify';
import api from '../network/ApiClient';

const EditLeg = ({ locations, leg, setLegs, setEditLeg, id, fetchRoute }) => {
  const [updatedLeg, setUpdatedLeg] = useState({ ...leg });
  const [loading, setLoading] = useState(false);
  const [isEtaModalOpen, setEtaModalOpen] = useState(false);
  const [isDwellModalOpen, setDwellModalOpen] = useState(false);
  const handleUpdateLeg = async () => {
    const errors = [];
    if (!updatedLeg.location) errors.push('Location');
    if (!updatedLeg.cost) errors.push('Cost');
    if (errors.length > 0) {
      toast.error(`Please fill all fields: ${errors.join(', ')}`);
      return;
    }

    setLoading(true);

    try {
      const legData = {
        location: Number(updatedLeg.location),
        eta: formatDurationForBackend(updatedLeg.eta),
        dwell: formatDurationForBackend(updatedLeg.dwell),
        cost: updatedLeg.cost,
        access: handleAccessCalculation(updatedLeg.embark, updatedLeg.disembark).toString(),
      };

      await api.post(`${process.env.REACT_APP_API_URL}route/${id}/updateleg/${leg.id}`, legData);
      fetchRoute();
      resetForm();
      setEditLeg(null);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setUpdatedLeg({ ...leg });
  };

  const formatDurationForPicker = (duration) => {
    return {
      hours: parseInt(duration.hours, 10) || 0,
      minutes: parseInt(duration.minutes, 10) || 0,
      seconds: parseInt(duration.seconds, 10) || 0,
    };
  };

  const formatDurationForBackend = (duration) => {
    return `${duration.hours?.toString().padStart(2, '0')}:${duration.minutes
      ?.toString()
      .padStart(2, '0')}:${duration.seconds?.toString().padStart(2, '0')}`;
  };

  const handleAccessCalculation = (embark, disembark) => {
    if (embark && disembark) return '3';
    if (embark) return '2';
    if (disembark) return '1';
    return '0';
  };

  const handleDurationChange = (type, value) => {
    if (type === 'eta') {
      setUpdatedLeg((prev) => ({ ...prev, eta: value }));
    } else if (type === 'dwell') {
      setUpdatedLeg((prev) => ({ ...prev, dwell: value }));
    }
  };

  const handleCheckboxChange = (field) => {
    setUpdatedLeg((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  console.log(formatDurationForPicker(updatedLeg.eta));
  return (
    <TableRow>
      <TableCell sx={{ width: '10%' }}>Edit</TableCell>
      <TableCell sx={{ width: '20%' }}>
        <Select
          value={updatedLeg.location}
          onChange={(e) => setUpdatedLeg({ ...updatedLeg, location: e.target.value })}
          sx={{ width: '200px' }}
        >
          {Object.entries(locations).map(([id, name]) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>

      <TableCell sx={{ width: '15%' }}>
        <Button variant="outlined" color="primary" onClick={() => setEtaModalOpen(true)}>
          {`${updatedLeg.eta?.hours || '00'}:${updatedLeg.eta?.minutes || '00'}:${updatedLeg.eta?.seconds || '00'}`}
        </Button>
        <Modal open={isEtaModalOpen} onClose={() => setEtaModalOpen(false)}>
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2rem',
              p: 2,
            }}
          >
            <DurationPicker
              initialDuration={{ hours: 0, minutes: 0, seconds: 0 }}
              onChange={(value) => handleDurationChange('eta', value)}
            />
            <Button variant="contained" onClick={() => setEtaModalOpen(false)}>
              Save ETA
            </Button>
          </Box>
        </Modal>
      </TableCell>

      <TableCell sx={{ width: '15%' }}>
        <Button variant="outlined" color="primary" onClick={() => setDwellModalOpen(true)}>
          {`${updatedLeg.dwell?.hours || '00'}:${updatedLeg.dwell?.minutes || '00'}:${
            updatedLeg.dwell?.seconds || '00'
          }`}
        </Button>
        <Modal open={isDwellModalOpen} onClose={() => setDwellModalOpen(false)}>
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2rem',
              p: 2,
            }}
          >
            <DurationPicker
              initialDuration={{ hours: 0, minutes: 0, seconds: 0 }}
              onChange={(value) => handleDurationChange('dwell', value)}
            />
            <Button variant="contained" onClick={() => setDwellModalOpen(false)}>
              Save Dwell
            </Button>
          </Box>
        </Modal>
      </TableCell>

      <TableCell sx={{ width: '20%' }}>
        <TextField
          type="number"
          value={updatedLeg.cost}
          onChange={(e) => setUpdatedLeg({ ...updatedLeg, cost: e.target.value })}
          placeholder="Cost"
        />
      </TableCell>

      <TableCell sx={{ width: '10%' }}>
        <Checkbox checked={updatedLeg.embark} onChange={() => handleCheckboxChange('embark')} />
      </TableCell>

      <TableCell sx={{ width: '10%' }}>
        <Checkbox checked={updatedLeg.disembark} onChange={() => handleCheckboxChange('disembark')} />
      </TableCell>

      <TableCell sx={{ width: '10%' }}>
        <Button variant="contained" color="primary" onClick={handleUpdateLeg} disabled={loading}>
          {loading ? 'Updating...' : 'Update'}
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => setEditLeg(null)}>
          Cancel
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default EditLeg;
