export function parseJwt(token) {
  try {
    // console.log(token);
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          const d = `00${c.charCodeAt(0).toString(16)}`.slice(-2);
          return `%${d}`;
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    // console.log(e);
    return {};
  }
}

export function getUser() {
  return parseJwt(localStorage.getItem('logged_user'));
}
