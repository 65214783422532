import React, { useEffect } from 'react';
import { Box, Button, Stack, Modal, TextField, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import api from '../network/ApiClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: 500, xs: '100%' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: { md: 4, xs: 2 },
};

export default function CreateDriverForm({ onCreateCallback, modalShow, setModalShow, edit, driver }) {
  const handleClose = () => {
    setModalShow(false);
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      phone: Yup.string()
        .matches(/^[0-9]+$/, 'Phone number must only contain digits')
        .min(10, 'Phone number must be at least 10 digits')
        .required('Phone Number is required'),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
          'Please enter a valid email address'
        )
        .required('Email is required'),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        firstname: values.firstName,
        lastname: values.lastName,
        phone: values.phone,
        email: values.email,
      };

      try {
        if (edit) {
          await api.put(`driver/${driver.id}`, payload);
        } else {
          await api.post('driver', payload);
        }
        onCreateCallback();
        formik.resetForm();
        handleClose();
      } catch (error) {
        console.error('Error saving driver:', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (modalShow) {
      formik.resetForm();
      if (driver) {
        formik.setValues({
          firstName: driver.firstname,
          lastName: driver.lastname,
          phone: driver.phone,
          email: driver.email,
        });
      }
    }
  }, [driver, modalShow]);

  const validateFields = () => {
    const errors = {};
    if (!formik.values.firstName) errors.firstName = 'First Name is required';
    if (!formik.values.lastName) errors.lastName = 'Last Name is required';
    if (!formik.values.phone) errors.phone = 'Phone Number is required';
    if (!formik.values.email) errors.email = 'Email is required';
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      toast.error(`Please fill in the following fields: ${Object.values(errors).join(', ')}`);
      return;
    }
    formik.handleSubmit();
  };

  return (
    <Modal
      open={modalShow}
      onClose={handleClose}
      aria-labelledby="create-driver-modal"
      aria-describedby="create-driver-form"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <h4 className="card-title mb-0">{edit ? 'Edit Driver' : 'Create Driver'}</h4>
            <TextField
              fullWidth
              name="firstName"
              label="First Name"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              variant="outlined"
              error={formik.errors.firstName && formik.touched.firstName}
              helperText={formik.errors.firstName && formik.touched.firstName ? formik.errors.firstName : ''}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              name="lastName"
              label="Last Name"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              variant="outlined"
              error={formik.errors.lastName && formik.touched.lastName}
              helperText={formik.errors.lastName && formik.touched.lastName ? formik.errors.lastName : ''}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              name="phone"
              label="Phone Number"
              onChange={formik.handleChange}
              value={formik.values.phone}
              variant="outlined"
              error={formik.errors.phone && formik.touched.phone}
              helperText={formik.errors.phone && formik.touched.phone ? formik.errors.phone : ''}
              InputLabelProps={{ shrink: true }}
              type="number"
            />
            <TextField
              fullWidth
              name="email"
              label="Email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
              error={formik.errors.email && formik.touched.email}
              helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ''}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="contained" type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? <CircularProgress size={24} color="inherit" /> : edit ? 'Save' : 'Submit'}
            </Button>
            <Button color="error" onClick={handleClose} disabled={formik.isSubmitting}>
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
