import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('logged_user')
    if (token) {
      config.headers.Authorization = `Bearer ${  localStorage.getItem('logged_user')}`
    } else {
      window.location.href = '/'
    }
    return config
  },
  (error) => Promise.reject(error),
)

export default api
