import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Autocomplete,
  Grid,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Box,
  Modal,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Edit, Trash, Clock, Calendar, PlusSquare } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CreateRouteForm from './CreateRouteForm';
import { loadLocations } from '../locations/Locations';
import Iconify from '../components/iconify';
import api from '../network/ApiClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    md: 500,
    xs: '100%',
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: {
    md: 4,
    xs: 2,
  },
};

export default function RoutesPage() {
  const navigate = useNavigate();
  const [modalNewEventGroup, setModalNewEventGroupModalShow] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editRoute, setEditRoute] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [routesError, setRoutesError] = useState(false);
  const [locations, setLocations] = useState({ data: [] });

  const fetchRoutes = async () => {
    try {
      const res = await api.get(`${process.env.REACT_APP_API_URL}/route`);
      if (res.data && res.data.length > 0) {
        const routesWithIds = res.data.map((route, index) => ({
          ...route,
          id: route.id || index,
        }));
        setRoutes(routesWithIds);
        setRoutesError(false);
      } else {
        setRoutes([]);
        setRoutesError(true);
      }
    } catch (error) {
      setRoutesError(true);
    }
  };

  const deleteRoute = async (id) => {
    try {
      const cfs = window.confirm('Do you want to Delete this Route?');
      if (!cfs) {
        return;
      }
      await api.delete(`/route/${id}/delete`);
      setRoutes(routes.filter((route) => route.id !== id));
    } catch (error) {
      console.error('Error deleting route:', error);
    }
  };

  const handleEditRoute = (route) => {
    setEditRoute(route);
    setEditModalOpen(true);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <Stack direction="row" spacing={1}>
          <IconButton color="secondary" size="small" onClick={() => navigate(`/rides/${cellValues.row.id}`)}>
            <Clock size={15} />
          </IconButton>
          <IconButton color="primary" size="small" onClick={() => navigate(`/routes/${cellValues.row.id}`)}>
            <Edit size={15} />
          </IconButton>

          <IconButton color="error" size="small" onClick={() => deleteRoute(cellValues.row.id)}>
            <Trash size={15} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    fetchRoutes();
    const fetchLocations = async () => {
      await loadLocations(setLocations);
    };
    fetchLocations();
  }, []);

  const handleCreateRoute = (newRoute) => {
    setRoutes([...routes, newRoute]);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setEditRoute(null);
  };

  // Initialize formik with default values from editRoute
  const formik = useFormik({
    initialValues: {
      name: '',
      legs: [{ location: '', access: '2' }],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Route name is required'),
      legs: Yup.array().of(
        Yup.object({
          location: Yup.string().required('Please select a location'),
          access: Yup.string().required('Access is required'),
        })
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      handleCloseEditModal();
    },
  });

  useEffect(() => {
    if (editRoute) {
      formik.setValues({
        name: editRoute.name,
        legs: [{ location: editRoute.legs[0]?.location || '', access: '2' }],
      });
    }
  }, [editRoute]);

  return (
    <>
      <Helmet>
        <title>Routes</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Routes</Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setModalNewEventGroupModalShow(true);
              }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create Route
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              sx={{
                padding: '7px 10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                fontSize: '16px',
                color: 'white',
              }}
              onClick={() => navigate(`/routes/calendar`)}
            >
              <Calendar size={20} /> View Calendar
            </Button>
          </Box>
        </Stack>

        <CreateRouteForm
          onCreateCallback={handleCreateRoute}
          modalShow={modalNewEventGroup}
          setModalShow={setModalNewEventGroupModalShow}
          onSubmitSuccess={fetchRoutes}
        />

        <Box sx={{ width: '100%', height: 400 }}>
          <DataGrid
            rows={routes}
            columns={columns}
            autoHeight
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            disableColumnMenu
            disableDensitySelector
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Box>

        <Modal
          open={editModalOpen}
          onClose={handleCloseEditModal}
          aria-labelledby="edit-route-modal"
          aria-describedby="edit-route-form"
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <h4 className="card-title mb-0">Edit Route</h4>

                <TextField
                  fullWidth
                  name="name"
                  label="Route Name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  variant="outlined"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{ shrink: true }}
                />

                <Autocomplete
                  options={locations.data}
                  getOptionLabel={(loc) => loc.name}
                  value={locations.data.find((loc) => loc.id === formik.values.legs[0]?.location) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('legs', [{ ...formik.values.legs[0], location: newValue?.id || '' }]);
                  }}
                  renderInput={(params) => <TextField {...params} label="Location" variant="outlined" fullWidth />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  freeSolo
                />
              </Stack>

              <Grid container sx={{ mt: 2 }} justifyContent="space-between">
                <Grid item xs={6}>
                  <Button variant="contained" type="submit" disabled={formik.isSubmitting}>
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Button color="error" onClick={handleCloseEditModal}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Container>
    </>
  );
}
