import React, { useState } from 'react';
import { Select, MenuItem, TextField, Button, Grid, Box, Alert, FormControl, InputLabel } from '@mui/material';
import { Plus } from 'react-feather';
import { toast } from 'react-toastify';
import { getUser } from '../helpers/DecodeJWT';
import api from '../network/ApiClient';

const ScheduleForm = ({
  routeId,
  setNewSchedule,
  newSchedule,
  handleNewScheduleChange,
  vehicles,
  drivers,
  fetchRides,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async () => {
    setErrorMessage('');
    if (
      newSchedule.start === '' ||
      newSchedule.end === '' ||
      newSchedule.every === '' ||
      newSchedule.nth === '' ||
      newSchedule.depart === '' ||
      !newSchedule.vehicle ||
      !newSchedule.driver
    ) {
      setErrorMessage('Please fill in all fields before submitting.');
      return;
    }

    const scheduleData = {
      start: newSchedule.start,
      end: newSchedule.end,
      every: Number(newSchedule.every),
      nth: Number(newSchedule.nth),
      depart: newSchedule.depart,
      vehicle: newSchedule.vehicle,
      driver: newSchedule.driver,
      route: Number(routeId),
      organizergroup: getUser().organizergroup,
    };

    try {
      const response = await api.post('/ride', scheduleData);
      if (response.data.failed > 0 && response.data.report && !response.data.added) {
        const errorMessages = Object.values(response.data.report);

        const uniqueMessages = new Set(errorMessages);

        const errorMsg = Array.from(uniqueMessages).join(' ');

        setErrorMessage(errorMsg);
        toast.error('Failed to Add Rides');
      } else {
        fetchRides();
        setErrorMessage('');
        toast.success(`${response.data.added} Rides Added`);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
    }
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate());
    return tomorrow.toISOString().split('T')[0];
  };

  return (
    <Box sx={{ marginBottom: 2, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <InputLabel id="from" sx={{ width: '4rem', color: 'black', fontSize: '18px' }}>
            From
          </InputLabel>
          <TextField
            type="date"
            value={newSchedule.start || getTomorrowDate()}
            onChange={(e) => handleNewScheduleChange('start', e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: getTomorrowDate() }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <InputLabel id="until" sx={{ width: '4rem', color: 'black', fontSize: '18px' }}>
            Until
          </InputLabel>
          <TextField
            type="date"
            value={newSchedule.end}
            onChange={(e) => handleNewScheduleChange('end', e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '40px',
            marginTop: '10px',
            marginLeft: '15px',
          }}
        >
          <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <InputLabel id="every" sx={{ width: '4rem', color: 'black', fontSize: '18px' }}>
              Every
            </InputLabel>
            <Select
              value={newSchedule.nth}
              onChange={(e) => handleNewScheduleChange('nth', e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem value={2}>
                2<sup>nd</sup>
              </MenuItem>
              <MenuItem value={3}>
                3<sup>rd</sup>
              </MenuItem>
              <MenuItem value={4}>
                4<sup>th</sup>
              </MenuItem>
              <MenuItem value={5}>
                5<sup>th</sup>
              </MenuItem>
              <MenuItem value={6}>
                6<sup>th</sup>
              </MenuItem>
              <MenuItem value={7}>
                7<sup>th</sup>
              </MenuItem>
              <MenuItem value={8}>
                8<sup>th</sup>
              </MenuItem>
              <MenuItem value={9}>
                9<sup>th</sup>
              </MenuItem>
              <MenuItem value={10}>
                10<sup>th</sup>
              </MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Select
              value={newSchedule.every}
              onChange={(e) => handleNewScheduleChange('every', e.target.value)}
              fullWidth
              sx={{ width: '25rem' }}
              displayEmpty
            >
              <MenuItem value={0} selected>
                Day
              </MenuItem>
              <MenuItem value={1}>Monday</MenuItem>
              <MenuItem value={2}>Tuesday</MenuItem>
              <MenuItem value={3}>Wednesday</MenuItem>
              <MenuItem value={4}>Thursday</MenuItem>
              <MenuItem value={5}>Friday</MenuItem>
              <MenuItem value={6}>Saturday</MenuItem>
              <MenuItem value={7}>Sunday</MenuItem>
              <MenuItem value={8}>Weekday</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <InputLabel id="at" sx={{ width: '2rem', color: 'black', fontSize: '18px' }}>
              At{' '}
            </InputLabel>
            <TextField
              type="time"
              value={newSchedule.depart}
              onChange={(e) => handleNewScheduleChange('depart', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Box>

        <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <InputLabel id="vehicle-label" sx={{ width: '6rem', color: 'black', fontSize: '18px' }}>
            Vehicle
          </InputLabel>
          <Select
            labelId="vehicle-label"
            value={newSchedule.vehicle}
            onChange={(e) => handleNewScheduleChange('vehicle', e.target.value)}
            fullWidth
            displayEmpty
          >
            {vehicles?.map((vehicle) => (
              <MenuItem key={vehicle.id} value={vehicle.id}>
                {vehicle.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <InputLabel id="driver-label" sx={{ width: '6rem', color: 'black', fontSize: '18px' }}>
            Driver
          </InputLabel>
          <Select
            labelId="driver-label"
            value={newSchedule.driver}
            onChange={(e) => handleNewScheduleChange('driver', e.target.value)}
            displayEmpty
            fullWidth
          >
            {drivers?.map((driver) => (
              <MenuItem key={driver.id} value={driver.id}>
                {`${driver.firstname} ${driver.lastname}`}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{
              padding: '7px 10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              fontSize: '16px',
              color: 'white',
            }}
            color="primary"
            onClick={handleFormSubmit}
            startIcon={<Plus />}
          >
            Add Schedule
          </Button>
        </Grid>
      </Grid>

      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
};

export default ScheduleForm;
