import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Stack, Button, Container, Typography, IconButton, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Edit, Trash } from 'react-feather';
import api from '../network/ApiClient';
import Iconify from '../components/iconify';
import CreateDriverForm from './CreateDriverForm';

export default function DriversPage() {
  const [modalNewDriver, setModalNewDriverShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'firstname', headerName: 'First Name', flex: 1 },
    { field: 'lastname', headerName: 'Last Name', flex: 1 },
    { field: 'phone', headerName: 'Phone Number', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <Stack direction="row" spacing={2}>
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              setEdit(true);
              setSelectedDriver(drivers.find((driver) => driver.id === cellValues.id));
              setModalNewDriverShow(true);
            }}
          >
            <Edit size={15} />
          </IconButton>
          <IconButton color="error" size="small" onClick={() => handleDelete(cellValues.id)}>
            <Trash size={15} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const handleDelete = async (id) => {
    const permission = window.confirm('Do you Want to Delete this Driver ?');
    if (!permission) {
      return;
    }
    try {
      await api.delete(`driver/${id}`);
      setDrivers(drivers.filter((driver) => driver.id !== id));
    } catch (error) {
      console.error('Error deleting driver:', error);
      setError('Failed to delete driver.');
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await api.get('driver', {
        params: {
          all: 'ok',
          simple: 'ok',
        },
      });
      setDrivers(response.data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Drivers</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Drivers
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setEdit(false);
              setSelectedDriver(null);
              setModalNewDriverShow(true);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Create Driver
          </Button>
        </Stack>

        <CreateDriverForm
          onCreateCallback={() => {
            setModalNewDriverShow(false);
            setLoading(true);
            fetchDrivers();
          }}
          modalShow={modalNewDriver}
          setModalShow={setModalNewDriverShow}
          edit={edit}
          driver={selectedDriver}
        />

        <Box sx={{ width: '100%', height: 400 }}>
          {loading ? (
            <Typography variant="h6" align="center">
              Loading...
            </Typography>
          ) : error ? (
            <Typography variant="h6" color="error" align="center">
              {error}
            </Typography>
          ) : (
            <DataGrid
              rows={drivers}
              columns={columns}
              pageSize={5}
            />
          )}
        </Box>
      </Container>
    </>
  );
}
