import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Typography,
  Button,
  Box,
  TextField,
} from '@mui/material';
import { ArrowLeftCircle, Edit, PlusCircle, Trash } from 'react-feather';
import { useParams, useNavigate } from 'react-router-dom';
import AddLeg from './AddLeg';
import EditLeg from './EditLeg';
import api from '../network/ApiClient';

const EditRoutes = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [legs, setLegs] = useState([]);
  const [locations, setLocations] = useState({});
  const [editLeg, setEditLeg] = useState(null);
  const [showNewLegInputs, setShowNewLegInputs] = useState(false);
  const [routename, setRouteName] = useState('');
  const [editRouteName, setEditRouteName] = useState(false);
  const [legId, setLegId] = useState(null);
  const bottomRef = useRef(null);

  const fetchRoute = async () => {
    try {
      const res = await api.get(`${process.env.REACT_APP_API_URL}route/${id}`);
      if (res.data && res.data[0]) {
        // Sort legs based on the 'sequence' field
        const sortedLegs = res.data[0].legs.sort((a, b) => a.sequence - b.sequence);
        setLegs(sortedLegs || []);
        setRouteName(res.data[0].name);
      } else {
        navigate('/routes');
      }
    } catch (error) {
      navigate('/routes');
    }
  };

  const parseDuration = (durationStr) => {
    const [hours, minutes, seconds] = durationStr.split(':').map(Number);
    return {
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
    };
  };

  useEffect(() => {
    const getAllLocations = async () => {
      try {
        const res = await api.get(`${process.env.REACT_APP_API_URL}location`);
        const locationMap = res.data.reduce((map, location) => {
          map[location.id] = location.name;
          return map;
        }, {});
        setLocations(locationMap);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRoute();
    getAllLocations();
  }, [id, navigate]);

  const handleDelete = async (ff) => {
    await api.post(`/route/${id}/removeleg/${ff}`);
    setLegs(legs.filter((leg) => leg.id !== legId));
    setLegId(null);
  };

  const updateRouteName = async () => {
    try {
      await api.put(`${process.env.REACT_APP_API_URL}/route/${id}`, { name: routename });
      setEditRouteName(false);
    } catch (error) {
      console.error('Error updating route:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      {editRouteName ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <TextField
            type="text"
            value={routename}
            onChange={(e) => setRouteName(e.target.value)}
            placeholder="Edit Route Name"
            sx={{ width: '250px' }}
          />
          <Button variant="contained" color="secondary" onClick={updateRouteName}>
            Save
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setEditRouteName(false)}>
            Cancel
          </Button>
        </Box>
      ) : (
        <Typography variant="h6" gutterBottom>
          <IconButton sx={{ color: 'black' }} onClick={() => navigate('/routes')}>
            <ArrowLeftCircle />
          </IconButton>
          {routename}
          <IconButton sx={{ color: 'black' }} onClick={() => setEditRouteName(true)}>
            <Edit />
          </IconButton>
        </Typography>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '10%' }}>Leg</TableCell>
              <TableCell sx={{ width: '20%' }}>Stop</TableCell>
              <TableCell sx={{ width: '15%' }}>Duration</TableCell>
              <TableCell sx={{ width: '15%' }}>Dwell</TableCell>
              <TableCell sx={{ width: '10%' }}>Distance (km)</TableCell>
              <TableCell sx={{ width: '10%' }}>Cost (N$)</TableCell>
              <TableCell sx={{ width: '10%' }}>Embark</TableCell>
              <TableCell sx={{ width: '10%' }}>Disembark</TableCell>
              <TableCell sx={{ width: '10%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {legs.map((leg) => (
              <TableRow key={leg.id}>
                <TableCell>{leg.sequence === 1 ? 'Start' : `Stop ${leg.sequence}`}</TableCell>
                <TableCell sx={{ width: '200px' }}>{locations[leg.location] || 'Unknown Location'}</TableCell>
                <TableCell>{leg.eta}</TableCell>
                <TableCell>{leg.dwell}</TableCell>
                <TableCell>{leg.distance}</TableCell>
                <TableCell>{leg.sequence === 1 ? '' : leg.cost ? `N$ ${leg.cost}` : ''}</TableCell>
                <TableCell>
                  {leg.sequence !== legs.length && (
                    <Checkbox checked={leg.access === '2' || leg.access === '3' || leg.sequence === 1} disabled />
                  )}
                </TableCell>
                <TableCell>
                  {leg.sequence !== 1 && (
                    <Checkbox
                      checked={leg.access === '1' || leg.access === '3' || leg.sequence === legs.length}
                      disabled
                    />
                  )}
                </TableCell>
                <TableCell>
                  {leg.sequence !== 1 && (
                    <>
                      <IconButton
                        sx={{ color: 'red' }}
                        size="small"
                        onClick={() => {
                          const consent = window.confirm('Do you want to delete this Leg?');
                          if (!consent) return;
                          handleDelete(leg.id);
                        }}
                      >
                        <Trash size={20} />
                      </IconButton>
                      <IconButton
                        sx={{ color: 'blue' }}
                        size="small"
                        onClick={() => {
                          setShowNewLegInputs(false);
                          setEditLeg({
                            ...leg,
                            embark: leg.access === '3' || leg.access === '2',
                            disembark: leg.access === '3' || leg.access === '1',
                            eta: parseDuration(leg.eta),
                            dwell: parseDuration(leg.dwell),
                          });
                          bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
                        }}
                      >
                        <Edit size={20} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box ref={bottomRef} sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        {!showNewLegInputs && editLeg === null && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlusCircle />}
            onClick={() => {
              setEditLeg(null);
              setShowNewLegInputs(true);
            }}
            sx={{ width: '200px', height: '45px' }}
          >
            Add New Leg
          </Button>
        )}
      </Box>
      {showNewLegInputs && editLeg === null && (
        <AddLeg locations={locations} setShowNewLegInputs={setShowNewLegInputs} setLegs={setLegs} id={id} />
      )}
      {editLeg && showNewLegInputs === false && (
        <EditLeg
          locations={locations}
          leg={editLeg}
          setLegs={setLegs}
          setEditLeg={setEditLeg}
          id={id}
          fetchRoute={fetchRoute}
        />
      )}
    </div>
  );
};

export default EditRoutes;
