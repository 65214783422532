import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../network/ApiClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    md: 500,
    xs: '100%',
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: {
    md: 4,
    xs: 2,
  },
};

export default function AddEditLocationForm({ onCreateCallback, modalShow, setModalShow, edit }) {
  const params = useParams();

  const handleOpen = () => {
    setModalShow(true);
  };
  const handleClose = () => {
    setModalShow(false);
  };

  const defaultValues = {
    organizergroup: null,
    name: '',
    description: '',
    address: '',
    city: '',
    longitude: '',
    latitude: '',
  };

  const [isEdit, setisEdit] = useState(false);
  const [eventGroupData, setEventGroupData] = useState({
    data: defaultValues,
  });

  const { resetForm, values, handleChange, errors, touched, setValues, handleSubmit, setFieldValue } = useFormik({
    initialValues: defaultValues,
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      address: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      organizergroup: Yup.object().required('Required'),
      longitude: Yup.string().required('Longitude is required'),
      latitude: Yup.string().required('Latitude is required'),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const v = { ...values };
      v.organizergroup = v.organizergroup?.id;
      v.longitude = parseFloat(v.longitude);
      v.latitude = parseFloat(v.latitude);
      api({
        method: isEdit ? 'put' : 'post',
        url: isEdit ? `${process.env.REACT_APP_API_URL}location/${edit}` : `${process.env.REACT_APP_API_URL}location/`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: v,
      })
        .then((res) => {
          if (!isEdit && res.data.added === true) {
            handleClose();
            onCreateCallback(res.data);
          } else if (isEdit && res.data.updated === true) {
            handleClose();
            onCreateCallback(res.data);
          } else alert('something went wrong');
        })
        .catch(() => {});
      setSubmitting(false);
    },
  });

  const loadLocationData = async () => {
    await api
      .get(`location/${edit}`, {
        params: {
          all: 'ok',
          simple: 'ok',
        },
      })
      .then((res) => {
        res.data.organizergroup =
          optionState.data.filter((v) => v.id === parseInt(res.data.organizergroup, 10))[0] ?? {};
        setEventGroupData({
          ...eventGroupData,
          data: res.data,
        });
        setisEdit(true);
        setValues(res.data, false);
      });
  };

  useEffect(() => {
    setEventGroupData({
      data: defaultValues,
    });
    setValues(defaultValues, false);
    if (typeof edit === 'number') {
      loadLocationData();
    } else {
      setisEdit(false);
    }
  }, [modalShow]);

  const [optionState, setOptionState] = useState({ data: [] });

  const loadOrganizerGroups = async () => {
    await api
      .get('organizergroup', {
        params: {},
      })
      .then((res) => {
        setOptionState({ ...optionState, data: res.data.organizer });
      })
      .catch((e) => {
        console.log('unable to  load operators ', e);
      });
  };

  useEffect(() => {
    loadOrganizerGroups();
  }, []);

  return (
    <Modal
      open={modalShow}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <h4 className="card-title mb-0">{typeof edit === 'number' ? `Edit` : `Create`} Location</h4>
            <TextField
              fullWidth
              name="name"
              label="Name"
              onChange={handleChange}
              variant="outlined"
              value={values.name}
              error={errors.name && touched.name}
              helperText={errors.name && touched.name ? errors.name : ''}
            />
            <TextField
              fullWidth
              name="description"
              label="Description"
              onChange={handleChange}
              variant="outlined"
              value={values.description}
              multiline
              rows={4}
              error={errors.description && touched.description}
              helperText={errors.description && touched.description ? errors.description : ''}
            />

            <TextField
              fullWidth
              name="address"
              label="Address"
              onChange={handleChange}
              variant="outlined"
              value={values.address}
              multiline
              rows={4}
              error={errors.address && touched.address}
              helperText={errors.address && touched.address ? errors.address : ''}
            />

            <TextField
              fullWidth
              name="city"
              label="City"
              onChange={handleChange}
              variant="outlined"
              value={values.city}
              error={errors.city && touched.city}
              helperText={errors.city && touched.city ? errors.city : ''}
            />

            <div style={{ display: 'flex', gap: '10px' }}>
              <TextField
                fullWidth
                name="longitude"
                label="Longitude"
                onChange={handleChange}
                variant="outlined"
                value={values.longitude}
                error={errors.longitude && touched.longitude}
                helperText={errors.longitude && touched.longitude ? errors.longitude : ''}
              />
              <TextField
                fullWidth
                name="latitude"
                label="Latitude"
                onChange={handleChange}
                variant="outlined"
                value={values.latitude}
                error={errors.latitude && touched.latitude}
                helperText={errors.latitude && touched.latitude ? errors.latitude : ''}
              />
            </div>

            <FormControl error={Boolean(errors.organizergroup && touched.organizergroup)} fullWidth>
              <Autocomplete
                options={optionState.data}
                onChange={(event, value) => setFieldValue('organizergroup', value)}
                getOptionLabel={(option) => `${option.name}`}
                value={values.organizergroup}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Organizer Group"
                    variant="outlined"
                    error={Boolean(errors.organizergroup && touched.organizergroup)}
                    helperText={errors.organizergroup && touched.organizergroup ? errors.organizergroup : ''}
                  />
                )}
              />
            </FormControl>
          </Stack>

          <Grid container sx={{ mt: 2 }} justifyContent={'space-between'}>
            <Grid xs={6}>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
            <Grid xs={6} textAlign={'right'}>
              <Button color="error" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
