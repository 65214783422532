import React, { useEffect } from 'react';
import { Box, Button, Stack, Modal, TextField, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../network/ApiClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: 500, xs: '100%' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: { md: 4, xs: 2 },
};

const vehicleValidationSchema = Yup.object({
  name: Yup.string().required('Vehicle name is required'),
  type: Yup.string().required('Vehicle type is required'),
  number: Yup.string().required('Vehicle number is required'),
  capacity: Yup.number()
    .required('Capacity is required')
    .positive('Capacity must be a positive number')
    .integer('Capacity must be an integer'),
  status: Yup.string().required('Status is required'),
  costperkm: Yup.number().required('Cost per kilometer is required').positive('Cost must be a positive number'),
});

const CreateVehicleForm = ({ onCreateCallback, modalShow, setModalShow, edit, vehicle }) => {
  const handleClose = () => {
    setModalShow(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      number: '',
      capacity: '',
      status: 'Available',
      costperkm: '',
    },
    enableReinitialize: true,
    validationSchema: vehicleValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        if (edit && vehicle?.id) {
          await api.put(`/vehicle/${vehicle.id}`, values);
        } else {
          await api.post('/vehicle', values);
        }
        onCreateCallback();
        handleClose();
        resetForm();
      } catch (error) {
        console.error('Error creating/updating vehicle:', error);
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (edit && vehicle) {
      formik.setValues(vehicle);
    }
  }, [edit, vehicle]);

  const renderTextField = (name, label, type = 'text', select = false, options = []) => (
    <TextField
      fullWidth
      name={name}
      label={label}
      type={type}
      select={select}
      onChange={formik.handleChange}
      value={formik.values[name]}
      variant="outlined"
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] ? formik.errors[name] : ''}
      InputLabelProps={{ shrink: true }}
    >
      {select &&
        options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );

  return (
    <Modal
      open={modalShow}
      onClose={handleClose}
      aria-labelledby="create-vehicle-modal"
      aria-describedby="create-vehicle-form"
    >
      <Box sx={style}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <h4 className="card-title mb-0">{edit ? 'Edit Vehicle' : 'Create Vehicle'}</h4>
            {renderTextField('name', 'Name')}
            {renderTextField('type', 'Type')}
            {renderTextField('number', 'Number')}
            {renderTextField('capacity', 'Capacity', 'number')}
            {renderTextField('status', 'Status', undefined, true, [
              { value: 'Available', label: 'Available' },
              { value: 'Unavailable', label: 'Unavailable' },
            ])}
            {renderTextField('costperkm', 'Cost per Kilometer', 'number')}

            <Button fullWidth type="submit" variant="contained" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? 'Submitting...' : edit ? 'Update Vehicle' : 'Create Vehicle'}
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateVehicleForm;
