import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftCircle } from 'react-feather';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { getUser } from '../helpers/DecodeJWT';
import api from '../network/ApiClient';

const CalendarView = () => {
  const [rides, setRides] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().toISOString().slice(0, 7));
  const { routeId } = useParams();

  const fetchRides = async (month) => {
    try {
      const res = await api.get(
        `/ride?route=${!routeId ? '' : routeId}&organizergroup=${getUser().organizergroup}&month=${month}`
      );
      if (res.data) {
        setRides(
          res.data.map((ride) => ({
            id: ride.id,
            title: `${ride.routename} - ${new Date(ride.depart).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}`,
            start: ride.depart.split(' ')[0],
            allDay: true,
            drivername: ride.drivername,
            vehiclename: ride.vehiclename,
            departTime: `${new Date(ride.depart).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}`
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching rides:', error);
    }
  };

  useEffect(() => {
    fetchRides(currentMonth);
  }, [currentMonth]);

  const handleDateChange = (date) => {
    const monthDate = new Date(date);
    monthDate.setMonth(monthDate.getMonth() + 1);
    const month = monthDate.toISOString().slice(0, 7);
    setCurrentMonth(month);
    fetchRides(month);
  };

  const handleEventDidMount = (info) => {
    const event = info.event.extendedProps;
    console.log(info)
    tippy(info.el, {
      content: `<div>Driver: ${event.drivername} <br/> Vehicle: ${event.vehiclename} <br/> Departure Time: ${event.departTime}</div>`,
      allowHTML: true,
      theme: 'light',
    });
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Link
        to={!routeId ? '/routes' : `/rides/${routeId}`}
        style={{
          width: '6rem',
          height: '2.5rem',
          padding: '10px',
          color: 'white',
          backgroundColor: 'black',
          borderRadius: '5px',
          textDecoration: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <ArrowLeftCircle /> Back
      </Link>
      <FullCalendar
        dayPopoverFormat={{ month: 'long', day: 'numeric' }}
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={rides}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth',
        }}
        editable={false}
        datesSet={(dateInfo) => handleDateChange(dateInfo.start)}
        eventDidMount={handleEventDidMount}
        eventContent={(eventInfo) => (
          <div style={{ whiteSpace: 'normal', overflow: 'visible', width: '100%' }}>
            <b>{eventInfo.event.title}</b>
          </div>
        )}
      />
    </div>
  );
};

export default CalendarView;
