import { Box, Stack, Typography, Button, IconButton, Card } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Edit, Plus, Trash } from 'react-feather';
import api from '../network/ApiClient';
import AddEditLocationForm from './AddEditLocationForm';

export const loadLocations = async (setLocations, locations) => {
  await api
    .get('location')
    .then((res) => {
      setLocations({ ...locations, data: res.data.reverse() });
    })
    .catch((e) => {
      console.log('Unable to load locations', e);
    });
};

function Locations() {
  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'city',
      headerName: 'City',
      editable: false,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      editable: false,
      flex: 1,
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      editable: false,
      flex: 1,
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      editable: false,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      editable: false,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Stack direction={'row'} spacing={2}>
            <IconButton
              variant="outlined"
              color="primary"
              size="small"
              children={<Edit size={15} />}
              title="Edit Location"
              onClick={() => {
                setEditLocation(cellValues.id);
                setModalShow(true);
              }}
            />
            <IconButton
              variant="outlined"
              color="error"
              size="small"
              onClick={async () => {
                if (window.confirm('Are you sure you want to delete this location?')) {
                  await api.delete(`location/${cellValues.id}`).then(() => {
                    loadLocations(setLocations, locations);
                  });
                }
              }}
              children={<Trash size={15} />}
              title="Delete Location"
            />
          </Stack>
        );
      },
    },
  ];

  const [locations, setLocations] = useState({ data: [] });
  const [modalShow, setModalShow] = useState(false);
  const [editLocation, setEditLocation] = useState(false);

  useEffect(() => {
    loadLocations(setLocations, locations);
  }, []);

  return (
    <>
      <Stack spacing={4} minHeight={400} height={'auto'}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant="h4">Locations</Typography>

          <Stack direction={'row'} spacing={2}>
            <Button
              onClick={() => {
                setModalShow(true);
                setEditLocation(false);
              }}
              variant="contained"
              color="primary"
              startIcon={<Plus size={15} />}
            >
              Create Location
            </Button>
          </Stack>
        </Stack>
        <Card>
          <DataGrid
            autoHeight
            rows={locations.data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableDensitySelector
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Card>
      </Stack>

      <AddEditLocationForm
        modalShow={modalShow}
        onCreateCallback={() => {
          loadLocations(setLocations, locations);
        }}
        setModalShow={setModalShow}
        edit={editLocation}
      />
    </>
  );
}

export default Locations;
